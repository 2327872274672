import React, { Component } from 'react';
import {createBrowserHistory} from 'history';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import { Link } from 'react-router-dom';
import "swiper/css/bundle";

import { Footer } from './components/layout/Footer';
import { Header } from './components/layout/Header';

import Home from './components/Home';
import About_us from './components/pages/About_us';
import Contact_us from './components/pages/Contact_us';
import Kazan_volga_region_federal_university from './components/pages/Kazan_volga_region_federal_university';
import Volgograd_state_medical_university from './components/pages/Volgograd_state_medical_university';
import Peoples_friendship_university_medical_institute from './components/pages/Peoples_friendship_university_medical_institute';
import North_ossetian_state_medical_academy from './components/pages/North_ossetian_state_medical_academy';
import North_western_state_medical_university_named_after_mechnikov from './components/pages/North_western_state_medical_university_named_after_mechnikov';
import Apply_now from './components/pages/Apply_now';
import Mbbs_in_russia from './components/pages/Mbbs_in_russia';
import Thank_you from "./components/pages/Thank_you";


const appHistory = {
  basename: process.env.PUBLIC_URL
};
const history = createBrowserHistory(appHistory);
function App() {
 
  return (
    <div className="page">
      <Header />
   
      <Router basename={process.env.PUBLIC_URL} history={history}>  
      <Route exact path='/' component={ Home } />
      <Route exact path="/about-us" component={About_us} /> 
      <Route exact path="/contact-us" component={Contact_us} /> 
      <Route exact path="/kazan-volga-region-federal-university" component={Kazan_volga_region_federal_university} /> 
      <Route exact path="/volgograd-state-medical-university" component={Volgograd_state_medical_university} />
      <Route exact path="/peoples-friendship-university-medical-institute" component={Peoples_friendship_university_medical_institute} />
      <Route exact path="/north-ossetian-state-medical-academy" component={North_ossetian_state_medical_academy} />
      <Route exact path="/north-western-state-medical-university-named-after-mechnikov" component={North_western_state_medical_university_named_after_mechnikov} />
      <Route exact path="/apply-now" component={Apply_now} />
      <Route exact path="/mbbs-in-russia" component={Mbbs_in_russia} />
      <Route exact path="/thank-you" component={Thank_you} />

      
      
      
        
  
       
    <Footer />  
    </Router>  
   
 
    </div> 
  );
 }

export default App;
