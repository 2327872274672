import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import SideMenu from '../layout/SideMenu';


export class North_ossetian_state_medical_academy extends Component {
    render() {

        return (
            <main>
                <Helmet>
                    <title>North Ossetian State Medical Academy</title>
                    <meta name="description" content="North Ossetian State Medical Academy is a government university which is governed by Ministry of Health and Education of Russian Federation. North-Ossetian State Medical Academy is also known as NOSMA, NOGMA." />
                    <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                    <link rel="canonical" href="https://thedoctorsiea.org/north-ossetian-state-medical-academy/" />
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="North Ossetian State Medical Academy" />
                    <meta property="og:description" content="North Ossetian State Medical Academy is a government university which is governed by Ministry of Health and Education of Russian Federation. North-Ossetian State Medical Academy is also known as NOSMA, NOGMA." />
                    <meta property="og:url" content="https://thedoctorsiea.org/north-ossetian-state-medical-academy/" />
                    <meta property="og:site_name" content="MBBS in Russia" />
                    <meta property="article:modified_time" content="2021-03-05T06:25:23+00:00" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:label1" content="Est. reading time" />
                    <meta name="twitter:data1" content="4 minutes" />
                </Helmet>
                <section class="breadcrumb-area a1">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="inner-content clearfix">
                                    <div class="title">
                                        <h1>North Ossetian State Medical Academy</h1>
                                    </div>
                                    <div class="breadcrumb-menu float-right">
                                        <ul class="clearfix">
                                            <li><a href={process.env.PUBLIC_URL + '/'}>Home</a></li>
                                            <li class="active">North Ossetian State Medical Academy</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="company-overview-area">
                    <div class="container">

                        <div class="row">
                            <div class="col-xl-12">
                                <div class="history-content-box clearfix">


                                    <div class="single-history-content">

                                        <div class="text-box">
                                            <div class="inner">

                                                <div class="text">

                                                    <h2 class="title">North-Ossetian State Medical Academy | Official Representative | <a href="https://thedoctorsiea.in/" target="_blank">MBBS in Russia</a> | 2023 Admissions | Direct Admissions: MBBS, BDS, Pharmacy</h2>

                                                    <p>North – Ossetian State Medical Academy is located in the town of Vladikavkaz one of the most beautiful towns of the Large Caucasian Mountains. The nature of the North – Ossetia is picturesque and original. Here we can see magnificent mountains, capped with snow and glaciers, green alpine meadows, and impetuous rivers. The climate of North – Ossetia is temporally continental. The winter here is mild, but summer sometimes is dry due to the influence of the hot middle east desert’s air. Vladikavkaz is a large densely populated town with a multinational population. Various cultures are integrated here. There are many possibilities for sport and cultural rest with convenient transport communication. NOSMA is one of the best ones in the North Caucasus. The international activity was put in use from 1991, for these periods many specialists have been prepared for foreign countries such as Syria, India, Lebanon, Morocco, Sudan, Djibouti, Greece, Baltic States, etc.</p>
                                                    <p>NOSMA was founded in 1939. For the period of its existence, over 21 000 highly qualified specialists have graduated from it. The graduates of NOSMA successfully work not only in the republic but in all regions of the Russian Federation and abroad too. At the 41 chairs of the academy work 384 professors and teachers: 53 Doctors of Medical Sciences, 34 Professors, 242 Candidates of Medical Sciences, and 62 Assistant Doctors.</p>

                                                    <p>NOSMA has its own clinic and stomatological polyclinic with modern equipment. Clinical chairs of the academy are based in large healthcare institutions of the republic, the bed space of which is more than 6300 locations. Along with the professional training we have all the conditions for research work. There is a research laboratory in the Academy and Institute of biomedical research of Vladikavkaz scientific center of the Russian Academy of Sciences and of RNO-Alania Government. The Academy operates the Students’ Scientific Society and the association of young scientists. Students of the Academy take part in international conferences and workshops. Students have access to a library with a rich fund of special literature and fiction numbering more than 428000 copies of publications. The library operates an electronic reading room equipped with computers with Internet access.</p>
                                                    <p>The Academy has four sports and fitness centers: a shooting gallery, a sports ground. There are museums: of the Health Organization of North-Alania, biological and anatomical museums at the territory of the Academy.</p>

                                                    <h3>Why people choose NOSMA?</h3>

                                                    <ul>
                                                        <li>Unique teaching system</li>
                                                        <li>Specialized university</li>
                                                        <li>Good value for money</li>
                                                    </ul>

                                                    <h3>North Ossetian State Medical Academy Faculties:</h3>

                                                    <p>There are 6 faculties in the academy: medical, pediatric, stomatological, pharmaceutical, medico – preventive, and the faculty of post-graduate education (internship, residency, postgraduate course). The faculty of postgraduate education is preparing to graduate in 15 specialties, clinical residency in 31 specialties. Annually specialization and advanced training in 25 specialties is conducted in the academy. NOSMA operates the department of pre-university training and further education for foreigners.</p>

                                                    <h3>North Ossetian State Medical Academy Ranking:</h3>

                                                    <p>NOSMA Country Rank: 351 and world Ranking 9793</p>

                                                    <h3>North Ossetian State Medical Academy Recognition:</h3>

                                                    <ul>
                                                        <li>Ministry of Public Health and Education, Russian Federation.</li>
                                                        <li>National Medical Commission. (NMC)</li>
                                                        <li>World Health Organization.</li>
                                                        <li>Pakistan Medical and Dental Council.</li>
                                                        <li>Arab &amp; African Countries Medical Councils.</li>
                                                    </ul>

                                                    <h3>North Ossetian State Medical Academy Accommodation:</h3>

                                                    <p>NOSMA government hostels are available in a five-minute walk from the Academy building. But because of more students, NOSMA foreign student representative has to provide private hostels or flats for incoming students which will be nearby University campus. It’s very good and comfortable as compared to the government hostels.</p>

                                                    <h3>North Ossetian State Medical Academy | Fee structure:</h3>

                                                    <p>The fees structure for 2023: <a href="https://thedoctorsiea.in/mbbs-fees-russia/">click here </a></p>

                                                    <h3>North Ossetian State Medical Academy Admission | Admission procedure:</h3>

                                                    <p>This 81 years old higher-education institution has a selective admission policy based on past academic records and grades.  To apply to North Ossetian State Medical Academy follow these next steps. Start your application process by contacting the university authorized representative to get information about the necessary steps, as the admissions algorithm may vary for different countries.</p>
                                                    <p>The admission process begins by you sending us the scanned copy of the documents listed below, for more details or free counseling consult Doctors-IEA.</p>
                                                    <ul>
                                                        <li>Filled application form</li>
                                                        <li>Passport</li>
                                                        <li>secondary school certificate or transcript</li>
                                                        <li>Medical fitness certificate</li>
                                                        <li>Photos (passport size 35*45mm at the matte paper)</li>
                                                    </ul>

                                                    <h3>Vladikavkaz City, Russia</h3>

                                                    <p>City Day‎: ‎25th September, Country‎: ‎Russia, Founded: May 6, 1784</p>
                                                    <p>Vladikavkaz is the capital city of the Republic of North Ossetia-Alania, Russia. It is located in the southeast of the republic at the foothills of the Caucasus Mountains, situated on the Terek River. Vladikavkaz is one of the most populous cities in the North Caucasus. The city is an industrial and transportation center. Manufactured products include processed zinc and lead, machinery, chemicals, clothing, and food products.</p>
                                                    <p>The city was founded in 1784 as a fortress during the Russian conquest of the Caucasus and was for many years the main Russian military base in the region. The Georgian Military Highway, crossing the mountains, was constructed in 1799 to link the city with Georgia to the south, and in 1875 a railway was built to connect it to Rostov-on-Don and Baku in Azerbaijan. Vladikavkaz has become an important industrial center for the region, with smelting, refining, chemicals, and manufacturing industries.</p>
                                                    <p>The city is one of the largest in the Russian-controlled Caucasus, along with Grozny, and was the capital of the Mountain Autonomous Soviet Socialist Republic, the Soviet Republic established after the annexation of the Mountainous Republic of the North Caucasus. The puppet state existed from 1921 to 1924 and was part of, and in some cases incorporated, the modern-day territories of Chechnya, North Ossetia, and Kabardino-Balkaria.</p>
                                                    <p>International students can take direct flights from Moscow or can travel by train. Vladikavkaz International Airport is located in the city. (Every day flights are available from Moscow)</p>

                                                    <h4>How to get Direct MBBS Admission in NOSMA, Russia?</h4>

                                                    <p>For admission procedure contact <a href="https://thedoctorsiea.in/">Doctors-IEA Team </a>who is the official authorized representative of NOSMA for Indians &amp; International students. For more queries, you can call us at 1800-419-5827 (Toll-free) / +91-8010503035 (India) | +79515174507 (Russia). </p>

                                                    <p><strong>Call for more details about the university, admission, processing, fees at MBBS in NOSMA<br /> Russia:+91-8010503035, Toll-free 1800-419-5827</strong></p>


                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>


                    </div>
                </section>




            </main>

        )
    }

}

export default North_ossetian_state_medical_academy;