import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import SideMenu from '../layout/SideMenu';


export class Peoples_friendship_university_medical_institute extends Component {
  render() {

    return (
      <main>
        <Helmet>
          <title>Peoples Friendship University - Medical Institute</title>
          <meta name="description" content="Peoples Friendship University is a government university which is governed by Ministry of Education &amp; Science of Russian Federation. PFUR Moscow is recognized by National Medical Commission &amp; World Health Organization." />
          <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
          <link rel="canonical" href="https://thedoctorsiea.org/peoples-friendship-university-medical-institute/" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Peoples Friendship University - Medical Institute" />
          <meta property="og:description" content="Peoples Friendship University is a government university which is governed by Ministry of Education &amp; Science of Russian Federation. PFUR Moscow is recognized by National Medical Commission &amp; World Health Organization." />
          <meta property="og:url" content="https://thedoctorsiea.org/peoples-friendship-university-medical-institute/" />
          <meta property="og:site_name" content="MBBS in Russia" />
          <meta property="article:modified_time" content="2021-03-05T06:12:44+00:00" />
          <meta property="og:image" content="https://thedoctorsiea.org/wp-content/uploads/2021/01/PFUR-DoctorsIEA.png" />
          <meta property="og:image:width" content="1000" />
          <meta property="og:image:height" content="500" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:label1" content="Est. reading time" />
          <meta name="twitter:data1" content="5 minutes" />
        </Helmet>
        <section class="breadcrumb-area a1">
          <div class="container">
            <div class="row">
              <div class="col-xl-12">
                <div class="inner-content clearfix">
                  <div class="title">
                    <h1>Peoples Friendship University</h1>
                  </div>
                  <div class="breadcrumb-menu float-right">
                    <ul class="clearfix">
                      <li><a href={process.env.PUBLIC_URL + '/'}>Home</a></li>
                      <li class="active">Peoples Friendship University</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="company-overview-area">
          <div class="container">

            <div class="row">
              <div class="col-xl-12">
                <div class="history-content-box clearfix">


                  <div class="single-history-content">

                    <div class="text-box">
                      <div class="inner">

                        <div class="text">

                          <h2 class="title">Peoples’ Friendship University | Official Representative | <a href="https://thedoctorsiea.in/" target="_blank">MBBS in Russia</a> | 2023 Admissions | Direct Admissions: MBBS, BDS, Pharmacy</h2>
                          <p>Today Peoples’ Friendship University (RUDN) is one of the leading state higher educational institutions of Russia. It is the only university in the world every year uniting students from 140-150 countries.</p>
                          <p>Peoples’ Friendship University (RUDN) has a multi-profile structure of faculties and majors, typical for classical universities of the world. More than 77 thousand graduates of the University work in 170 countries, among them more than 5500 holders of Ph.D. and Doctorate degrees. Specialists are prepared in 62 majors and lines of study. More than 29 thousand graduate and postgraduate students from 140 countries are currently studying at the university. They represent more than 450 nations and nationalities of the world.  Welcome to study PFU, Moscow.</p>

                          <h3>Welcome to study MBBS in Moscow</h3>

                          <p>Peoples’ Friendship University has students from 145-150 countries marks it out as a highly reputable educational institution, whose diplomas make graduates competitive in the labor market thus giving them a chance to become state and political figures, members, and heads of their governments and parliaments. The time spent at the university does not only give professional knowledge, skills of working in a multinational team, and proficiency in a few foreign languages no matter what major students choose.</p>
                          <p>Education at the Peoples’ Friendship University of Russia creates the strategic potential for a further successful career on the basis of personal contacts with numerous alumni from many countries of the world, it facilitates building a career and solving problems of the companies and organizations they work for.</p>
                          <h3>PFUR faculties:</h3>
                          <ul>
                            <li>Engineering faculty</li>
                            <li>Faculty of Science</li>
                            <li>Medical faculty</li>
                            <li>Philological faculty</li>
                            <li>Ecological faculty</li>
                            <li>Faculty of Economics</li>
                            <li>Faculty of Law</li>
                            <li>Faculty of Humanitarian and social sciences</li>
                            <li>Institute of Hospitality business and tourism</li>
                            <li>Institute of World Economics and business</li>
                            <li>Institute of Foreign languages</li>
                            <li>Faculty of Refresher training for teachers of the Russian language.</li>
                          </ul>

                          <h3>Medical Faculty of People’s Friendship University Moscow:</h3>
                          <p>Peoples’ Friendship University Medical facultyhas a first-class reputation both in Russia and abroad; its fame rests on the scientists working at the faculty and the graduates. Nowadays, more than 6400 professionals that have been educated at the Medical faculty are working in more than 110 countries of the world. Prominent scientists whose contribution to medical science is well-known, are lecturing and leading practical seminars at the faculty. In numerous laboratories with up-to-date diagnostic and research equipment, students attempt to penetrate into the mysteries of structure and functioning of the human body and learn how to diagnose diseases and apply innovative methods of treatment.</p>
                          <p>The members of the Medical faculty in Peoples’ Friendship University are actively implementing innovative educational programs aimed at teaching new medical technologies to medical staff.</p>
                          <p>Functioning at the faculty is the Student’s scientific society that helps students become familiar with research from the beginning; the Young medical doctor’s community; the Students theatre «Hippocrates», the lecture center where lectures in literature, music, and history are presented. The pre-university education is provided at the «Medik» medical-biological school (preliminary courses).</p>
                          <p>Today, the Medical faculty of Peoples’ friendship university is equipped with 14 novel clinical laboratories; in the training process, computer testing and TV broadcasting are used. For refinement in practical treatment, subdivisions of computer tomography, lever fiber-scanning, and andrology have been created.</p>

                          <ul>
                            <li>PFUR  Admission:  One intake in a year for MBBS &amp; other courses for foreign students;<br />
                              Intake: June-September</li>
                          </ul>
                          <h3>PFUR Recognition:</h3>
                          <p>PFUR is recognized by the Ministry of Education and Science of the Russian Federation, WHO, NMC, EUA, ECFMG, and other Medical councils of Asia, Africa, and Arab countries.</p>
                          <h3>Peoples’ Friendship University Russia Ranking:</h3>
                          <p>Country Rank: 11, World Rank: 924. According to the Independent National University Ranking System in 2010 Peoples’ friendship University is ranked as the 4th best University of Russia among 104 Russian Universities. This Rating was published by International Information Agency INTERFAX. PFUR (RUDN) university is one of the best world-class universities in Russia ranked by Times Higher Education World University Rankings, QS World University Rankings, and Round University Ranking.</p>

                          <h3>Peoples’ Friendship University Russia Mission:</h3>
                          <p>The Peoples’ Friendship University of Russia is an international classic university. It is charged with the following mission:</p>
                          <ul>
                            <li>Uniting people of different nations, races, and religions by means of knowledge;</li>
                            <li>Training qualified specialists in various spheres of activity;</li>
                            <li>Forming personalities, who love their native countries and are friends of Russia; who are familiar with the achievements of world culture; who boost the ideals of humanism, democracy and peoples’ friendship;</li>
                            <li>Training young people, capable of working in any country of the world and using their creativity in the context of the interconnection of civilizations and diversity of modern society.</li>
                          </ul>
                          <h3>People’s Friendship University MBBS Fee structure:</h3>
                          <p>The fees structure for 2023: <a href="https://thedoctorsiea.in/mbbs-fees-russia/">click here </a></p>
                          <h3>Peoples’ Friendship University Russia Hostels:</h3>
                          <p>University having excellent hostels, 200 to 500 meters from the academic building.</p>

                          <h2>Admission Procedure to Study MBBS in PFUR, Russia in Simple Steps:</h2>
                          <ul>
                            <li>STEP-1: Call or Visit Doctors-IEA Team Official representative of University.</li>
                            <li>STEP-2: Properly fill the online application form of the PFUR.</li>
                            <li>STEP-3: After filling the online application form, the candidate will receive an offer letter from the university.</li>
                            <li>STEP-4: Pay processing fees and then submit all essential documents required to get admission to NMC recognized medical college-PFUR.</li>
                            <li>STEP-5: After receiving processing fees and original documents, the Doctors-IEA team will apply for an invitation letter and visa documentation.</li>
                            <li>STEP-6: After getting the Visa, the Doctors-IEA team will arrange tickets and all a proper set of documents to fly for a particular university with a group of students and their representatives.</li>
                          </ul>
                          <h3>What are the Eligibility Criteria for study MBBS at PFUR for Indians?</h3>
                          <p>Eligibility criteria for study MBBS in PFUR are based on your 12th standard marks or grades.</p>
                          <ul>
                            <li>You should be at the age of 17 on or before 31st December during the current year of admission.</li>
                            <li>The student must have more than 65% marks per each subject in ECB (English, Chemistry, Biology) in the 12th Class.</li>
                            <li>NEET  (Qualified marks) is mandatory to study MBBS in PFUR.</li>
                            <li>Only regular students can apply not from open school.</li>
                          </ul>
                          <p>The admission process begins by you sending us the scanned copy of the documents listed below, for more details or free counseling consult Doctors-IEA officially authorized representative of PFUR for Indian and Foreign students.</p>
                          <p>Documents required for study MBBS in PFUR (For Indian Students)</p>
                          <ul>
                            <li>Filled application form with fee guarantee letter.</li>
                            <li>Passport with a validity of a minimum of two years.</li>
                            <li>10th, 12th Certificates, and mark-sheets.</li>
                            <li>20  passport size photographs (35*45 mm @80% face at the matte finish paper with a white background.</li>
                            <li>Medical tests: HIV test, X-Ray Chest, Blood test, Health Fitness certificate.</li>
                          </ul>
                          <h4>For Other Countries: </h4>
                          <ul>
                            <li>Filled application form with fee guarantee letter</li>
                            <li>Passport with a validity of a minimum of two years.</li>
                            <li>Secondary school certificate (English, Chemistry &amp; Biology)</li>
                            <li>Medical tests: HIV test, X-Ray Chest, Blood test, Health Fitness certificate.</li>
                            <li>20 Photos (passport size 35*45 mm at the matte paper)</li>
                          </ul>
                          <p>We will get you the admission letter and invitation letter from the university. We will also guide you through the entire process and will complete all documentation including visa, ticket (for Indian students), For other countries aspirants, we will provide admission and invitation letter and will provide complete guidelines regarding visas, tickets. An incoming student enrolled through Doctors-IEA Team will receive by an official representative of PFUR at Moscow airport.</p>

                          <h4>How to get Direct MBBS Admission in PFUR, Moscow?</h4>

                          <p>For admission procedure contact <a href="https://thedoctorsiea.in/">Doctors-IEA Team </a>who is the official authorized representative of PFUR for Indians &amp; International students. For more queries, you can call us at 1800-419-5827 (Toll-free) / +91-8010503035 (India) | +79515174507 (Russia). </p>

                          <p><strong>Call for more details about the university, admission, processing, fees at MBBS in PFUR<br /> Russia:+91-8010503035, Toll-free 1800-419-5827</strong></p>




                        </div>
                      </div>
                    </div>
                  </div>



                </div>
              </div>
            </div>


          </div>
        </section>



      </main>

    )
  }

}

export default Peoples_friendship_university_medical_institute;