import React, { Component } from 'react';
import { Upcoming } from './layout/Upcoming';
import { Facebook } from './layout/Facebook';
import { Sliders } from './sliders/Sliders';
import { Services } from './sliders/Services';
import { Youtube } from './sliders/Youtube';
import { Clients } from './sliders/Clients';
import Faq from './layout/Faq';
import { Helmet } from "react-helmet";
import Courses from './sliders/Courses';




export class Home extends Component {

    render() {
        return (
            <main>
                <Helmet>
                    <title>Study MBBS in Russia for Indian Students | Study MBBS in Russia</title>
                    <meta name="description" content="Study MBBS in Russia for Indian Students, Admission Process, Eligibility Criteria, Fee Structure and documents required." />
                    <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                    <link rel="canonical" href="https://thedoctorsiea.in/" />
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="Study MBBS in Russia for Indian Students, Admission Process, Eligibility Criteria, Fee Structure and documents required" />
                    <meta property="og:description" content="Study MBBS in Russia for Indian Students, Admission Process, Eligibility Criteria, Fee Structure and documents required." />
                    <meta property="og:url" content="https://thedoctorsiea.in/" />
                    <meta property="og:site_name" content="MBBS in Russia" />
                    <meta property="article:modified_time" content="2021-11-01T05:13:01+00:00" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:label1" content="Est. reading time" />
                    <meta name="twitter:data1" content="8 minutes" />
                </Helmet>
                <section class="main-slider style2">
                    <div class="rev_slider_wrapper fullwidthbanner-container" id="rev_slider_one_wrapper" data-source="gallery">
                        <div class="rev_slider fullwidthabanner" id="rev_slider_two" data-version="5.4.1">
                            <Sliders />
                        </div>
                    </div>
                </section>
                <section class="about-style2-area">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-5">
                                <div class="about-style2-text">
                                    <div class="sec-title">
                                        <p>About Us</p>
                                        <div class="title">The Best &amp; <br /> Safest After<br /><span>  NEET 2023 options is</span></div>
                                    </div>
                                    <div class="text">
                                        <p>MBBS in Russia. Join World Renowned Medical Government Universities of Russia with Affordable fee structure. Book your seat in advance for 2023. Call Doctors-IEA team at 1800-419-5827 for Direct MBBS Admission.
                                        </p>
                                    </div>

                                    <div class="button">
                                        <a class="btn-one" href="https://thedoctorsiea.in/" target="_blank">More About Us<span class="flaticon-next"></span></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-7">
                                <div class="about-style2-image-box">
                                    <div class="pattern wow slideInUp" data-wow-delay="100ms" data-wow-duration="1500ms"></div>
                                    <div class="image">
                                        <img src="images/resources/about-style2-image.jpg" alt="Awesome Image" />
                                        <div class="overlay-box">
                                            <div class="title">
                                                <h1>25 <span>Years Experience</span></h1>
                                            </div>
                                            <div class="button">
                                                <a href="https://thedoctorsiea.in/" target="_blank"><span class="icon-back"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="slogan-style2-area bg1">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="inner-content">
                                    <div class="title">
                                        <h1>Doctors-IEA a perfect gateway <br /> <span>to your dream Medical Career!</span></h1>
                                    </div>
                                    <div class="button">
                                        <a class="btn-one call-us" href="#"><i class="icon-music"></i>+1800-419-5827</a>
                                        <a class="btn-one" href="https://thedoctorsiea.in/">Admission Procedure<span class="flaticon-next"></span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="why-choose-area bg2" >
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="why-choose-title float-left">
                                    <div class="sec-title">
                                        <div class="icon"><img src="images/resources/logo.png" alt="Awesome Logo" /></div>
                                        <div class="title">We Provide  <br />The Best <span> For You!</span></div>
                                    </div>
                                    <p>Dear applicants, Doctors-IEA is the official representative of the Prominent Medical Universities of Russia. We have more than 15 years of work experience with the Russian Medical Universities or with the education system of Russian Universities. Hundreds of our students are currently studying there & many of them graduated and working as a doctor in India & Other countries successfully. Nowadays hundreds of paid websites are on the internet and students</p>
                                    <div class="button">
                                        <a class="btn-one" href={process.env.PUBLIC_URL + '/mbbs-in-russia'}>Read More<span class="flaticon-next"></span></a>
                                    </div>
                                </div>
                                <div class="why-choose-content float-right">

                                    <div class="single-box redbg">
                                        <div class="icon-holder">
                                            <span class="fa fa-check"></span>
                                        </div>
                                        <div class="text-holder">
                                            <h3>Rostov State Medical University</h3>
                                            <p>Rostov State Medical University | Official Representative | MBBS in Russia | 2023...</p>
                                            <a class="btn-one mt-t" href="https://rrecrostov.com/" target="_blank">Read More<span class="flaticon-next"></span></a>
                                        </div>
                                    </div>

                                    <div class="single-box whitebg">
                                        <div class="icon-holder">
                                            <span class="fa fa-check"></span>
                                        </div>
                                        <div class="text-holder">
                                            <h3>Kazan State Medical University</h3>
                                            <p>Kazan State Medical University | Official Representative | MBBS in Russia | 2023
                                                ...</p>
                                            <a class="btn-one mt-t" href="https://thedoctorsiea.in/kazan-state-medical-university/" target="_blank">Read More<span class="flaticon-next"></span></a>
                                        </div>
                                    </div>

                                    <div class="single-box whitebg">
                                        <div class="icon-holder">
                                            <span class="fa fa-check"></span>
                                        </div>
                                        <div class="text-holder">
                                            <h3>Crimea Federal University</h3>
                                            <p>Crimea Federal University | Official Representative | MBBS in Russia | 2023 Admissions...</p>
                                            <a class="btn-one mt-t" href="https://rrecrussia.com/crimea-federal-university/" target="_blank">Read More<span class="flaticon-next"></span></a>
                                        </div>
                                    </div>

                                    <div class="single-box blackbg">
                                        <div class="icon-holder">
                                            <span class="fa fa-check"></span>
                                        </div>
                                        <div class="text-holder">
                                            <h3>Kazan (Volga region) Federal University</h3>
                                            <p>Kazan (Volga Region) Federal University (IFMB) | Official Representative | MBBS in Russia...</p>
                                            <a class="btn-one mt-t" href={process.env.PUBLIC_URL + '/kazan-volga-region-federal-university'} >Read More<span class="flaticon-next"></span></a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="recently-project-style2-area">
                    <div class="container">
                        <div class="sec-title text-center">
                            <p>Our Top Russia</p>
                            <div class="title">Medical  <span>Universities</span></div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12">

                                <Services />

                            </div>
                        </div>
                    </div>
                </section>


                <section class="working-process-style2-area bg3">
                    <div class="container">
                        <div class="sec-title">
                            <div class="title">Study Most Valuable  <span>Courses in Russia</span></div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12 col-lg-12">

                                <Courses />

                            </div>
                        </div>
                    </div>
                </section>

                <section class="contact-details-area">
                    <div class="sec-title text-center">
                        <p>Contact Details</p>
                        <div class="title clr-white">How Can We <span>Help You?</span></div>
                    </div>
                    <div class="parallax-background bg4"></div>
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="contact-details-content">

                                    <div class="row">
                                        <div class="col-xl-6 col-lg-6">
                                            <div class="content-info-box bg5">
                                                <div class="inner-content">
                                                    <div class="inner">
                                                        <div class="single-info-box wow fadeInDown" data-wow-delay="100ms" data-wow-duration="1200ms">
                                                            <div class="icon">
                                                                <span class="icon-global"></span>
                                                            </div>
                                                            <div class="text">
                                                                <h3>Visit Our Office</h3>
                                                                <p>Head Office: New Delhi (India) </p>
                                                            </div>
                                                        </div>
                                                        <div class="single-info-box white-bg wow fadeInUp" data-wow-delay="100ms" data-wow-duration="1200ms">
                                                            <div class="icon">
                                                                <span class="icon-global"></span>
                                                            </div>
                                                            <div class="text">
                                                                <h3>Visit Our Office</h3>
                                                                <p>Head Office: New Delhi (India)</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-6 col-lg-6">
                                            <div class="contact-details-form-box">
                                                <div class="title-box">
                                                    <h2>Get In Touch</h2>
                                                </div>
                                                <div class="contact-details-box">
                                                    <form class="contact-details-form" name="f1" method="post" id="f1" action="https://app.todesign.in/react/thedoctorsiea.org/contact.php">
                                                        <div class="row">
                                                            <div class="col-xl-6">
                                                                <div class="single-box">
                                                                    <input type="text" name="name" placeholder="Name" required />
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-6">
                                                                <div class="single-box">
                                                                    <input type="email" name="email" placeholder="Email" required />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-xl-12">
                                                                <div class="single-box">
                                                                    <input type="text" name="phone" placeholder="Phone" required />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-xl-12">
                                                                <div class="single-box">
                                                                    <textarea name="message" placeholder="Special Request" required></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-xl-12">
                                                                <div class="single-box">
                                                                    <button class="btn-one" type="submit">Send Request<span class="flaticon-next"></span></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </main>
        )
    }

}

export default Home;
