import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import SideMenu from '../layout/SideMenu';


export class North_western_state_medical_university_named_after_mechnikov extends Component {
  render() {

    return (
      <main>
        <Helmet>
          <title>North-Western State Medical University named after I.I. Mechnikov</title>
          <meta name="description" content="Petersburg State Medical Academy named after I.I. Mechnikov. The founder of the University is the Ministry of Health of the Russian Federation." />
          <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
          <link rel="canonical" href="https://thedoctorsiea.org/north-western-state-medical-university-named-after-mechnikov/" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="North-Western State Medical University named after I.I. Mechnikov" />
          <meta property="og:description" content="Petersburg State Medical Academy named after I.I. Mechnikov. The founder of the University is the Ministry of Health of the Russian Federation." />
          <meta property="og:url" content="https://thedoctorsiea.org/north-western-state-medical-university-named-after-mechnikov/" />
          <meta property="og:site_name" content="MBBS in Russia" />
          <meta property="article:modified_time" content="2021-03-05T06:34:35+00:00" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:label1" content="Est. reading time" />
          <meta name="twitter:data1" content="4 minutes" />
        </Helmet>
        <section class="breadcrumb-area a1">
          <div class="container">
            <div class="row">
              <div class="col-xl-12">
                <div class="inner-content clearfix">
                  <div class="title">
                    <h1>North-Western State Medical University<br /> named after I.I. Mechnikov</h1>
                  </div>
                  <div class="breadcrumb-menu float-right">
                    <ul class="clearfix">
                      <li><a href={process.env.PUBLIC_URL + '/'}>Home</a></li>
                      <li class="active">North-Western State Medical University named after I.I. Mechnikov</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="company-overview-area">
          <div class="container">

            <div class="row">
              <div class="col-xl-12">
                <div class="history-content-box clearfix">


                  <div class="single-history-content">

                    <div class="text-box">
                      <div class="inner">

                        <div class="text">
                          <h2 class="title">North-Western State Medical University named after I.I Mechnikov <br /> <a href="https://thedoctorsiea.in/" target="_blank">MBBS in Russia</a> | 2023 Admissions | Direct Admissions: MBBS, BDS, Pharmacy</h2>
                          <p>North-western State Medical University named after I. I. Mechnikov was formed on October 12-th,  2011, as a result of the merger of two oldest educational medical institutions in Russia – Saint-Petersburg Medical Academy of Postgraduate Studies and Saint-Petersburg State Medical Academy n. a. I.I. Mechnikov.</p>
                          <ul>
                            <li>The founder of Mechnikov university is the Ministry of public health of the Russian Federation.</li>
                            <li>The activity of the University is based on cooperation and coordination of educational, clinical, and scientific activity. It makes it possible to prepare competent specialists, who possess up-to-date knowledge and are able to use it in practice.</li>
                            <li>4,200 students (3000 students on budgetary education form and ~1200 on the fee-based educational form) are studying at the university, among them are 500 students from foreign countries.</li>
                            <li>Besides that, at the University are studying over 670 interns and about 1500 clinical residents, 460 Ph.D. candidates do their postgraduate work. Up to 35 000 specialists undergo their short- and long-term postdoctoral training annually.</li>
                            <li>Diagnostic and clinical work at Mechnikov University is managed in 6 own clinics, which capacity is 1645 beds and includes 25 clinical specializations. Clinical subdivisions of the University provide high-skilled medical help to 40000 patients at the hospital and about 300000 outpatients yearly.</li>
                            <li>The research work at Mechnikov University is conducted in accordance with recent scientific development directions of biomedical sciences. Considerable attention is paid to studies in the field of health protection and the sanitary and epidemiological welfare of the population.  The long-term strategy is focused on the effective transfer of fundamental and applied scientific action results and on building supportive scientific-educational environment, which will make possible to prepare specialist of highest qualification.</li>
                          </ul>
                          <h3>Highlights of North-Western State Medical University named after Mechnikov</h3>

                          <table cellpadding="8" cellspacing="8" class="table">
                            <tbody>
                              <tr>
                                <td>Degree offered</td>
                                <td>MD Physician (Equivalent to MBBS in India)</td>
                              </tr>
                              <tr>
                                <td>Course duration</td>
                                <td>5.8 Years</td>
                              </tr>
                              <tr>
                                <td>Teaching Medium</td>
                                <td>English Medium</td>
                              </tr>
                              <tr>
                                <td>Approved by</td>
                                <td>NMC &amp; WHO</td>
                              </tr>
                            </tbody>
                          </table>

                          <h3>Faculties:</h3>
                          <ul>
                            <li>Faculty of General Medicine</li>
                            <li>Faculty of Preventive Medicine</li>
                            <li>Pediatric faculty</li>
                            <li>Dental faculty</li>
                            <li>Surgical faculty</li>
                            <li>Therapeutic faculty</li>
                            <li>Biomedical faculty</li>
                            <li>Faculty of Nursing Care</li>
                          </ul>

                          <h3>Duration of Studying MBBS in NWSMU, Russia</h3>

                          <ul>
                            <li>The duration of the MBBS Course is 5.8 years for English Medium and 7 years for Russian Medium. BDS (Dentistry) 5 years English Medium, Pharmacy 5 years English Medium.</li>
                          </ul>

                          <h3>NWSMU Admission:</h3>
                          <ul>
                            <li>one intake in the year June – September</li>
                          </ul>

                          <h3>NWSMU Recognition:</h3>

                          <p>NWSMU is recognized by the Ministry of Health, Ministry of Education, and science of the Russian Federation. NWSMU is a government-funded university. NWSMU is recognized by MCI, WHO, ECFMG (USA), AMC, GMC, etc.</p>

                          <h3>NWSMU | MBBS Fees</h3>
                          <p>The fees structure for 2023: <a href="https://thedoctorsiea.in/mbbs-fees-russia/">click here </a></p>

                          <h3>Admission Procedure to Study MBBS in KazanSMU, Russia in Simple Steps:</h3>
                          <ul>
                            <li>STEP-1: Call or Visit Doctors-IEA Team representative of University.</li>
                            <li>STEP-2: Properly fill the online application form of the NWSMU.</li>
                            <li>STEP-3: After filling the online application form, the candidate will receive an offer letter from the university.</li>
                            <li>STEP-4: Pay processing fees and then submit all essential documents required to get admission to NMC recognized medical college-NWSMU.</li>
                            <li>STEP-5: After receiving processing fees and original documents, the Doctors-IEA team will apply for an invitation letter and visa documentation.</li>
                            <li>STEP-6: After getting the Visa, the Doctors-IEA team will arrange tickets and all a proper set of documents to fly for a particular university with a group of students and their representatives.</li>
                          </ul>
                          <h3>What are the Eligibility Criteria for study MBBS at NWSMU for Indians?</h3>
                          <p>Eligibility criteria for study MBBS in NWSMU are based on your 12th standard marks or grades.</p>
                          <ul>
                            <li>You should be at the age of 17 on or before 31st December during the current year of admission.</li>
                            <li>The student must have more than 65% marks per each subject in ECB (English, Chemistry, Biology) in the 12th Class.</li>
                            <li>NEET  (Qualified marks) is mandatory to study MBBS in NWSMU.</li>
                            <li>Only regular students can apply not from open school.</li>
                          </ul>
                          <p>The admission process begins by you sending us the scanned copy of the documents listed below, for more details or free counseling consult Doctors-IEA officially authorized representative of NWSMU for Indian and Foreign students.</p>
                          <h4>Documents required for study MBBS in NWSMU  (For Indian Students)</h4>
                          <ul>
                            <li>Filled application form with fee guarantee letter.</li>
                            <li>Passport with a validity of a minimum of two years.</li>
                            <li>10th, 12th Certificates, and mark-sheets.</li>
                            <li>20  passport size photographs (35*45 mm @80% face at the matte finish paper with a white background.</li>
                            <li>Medical tests: HIV test, X-Ray Chest, Blood test, Health Fitness certificate.</li>
                          </ul>
                          <h4>For Other Countries: </h4>
                          <ul>
                            <li>Filled application form with fee guarantee letter</li>
                            <li>Passport with a validity of a minimum of two years.</li>
                            <li>Secondary school certificate (English, Chemistry &amp; Biology)</li>
                            <li>Medical tests: HIV test, X-Ray Chest, Blood test, Health Fitness certificate.</li>
                            <li>20 Photos (passport size 35*45 mm at the matte paper)</li>
                          </ul>
                          <p>We will get you the admission letter and invitation letter from the university. We will also guide you through the entire process and will complete all documentation including visa, ticket (for Indian students), For other countries aspirants, we will provide admission and invitation letter and will provide complete guidelines regarding visas, tickets. An incoming student enrolled through Doctors-IEA Team will receive by a representative of the university at Moscow airport or at St. Petersburg airport</p>

                          <h4>How to get Direct MBBS Admission in NWSMU, Russia?</h4>

                          <p>For admission procedure contact <a href="https://thedoctorsiea.in/">Doctors-IEA Team </a>who is the official authorized representative of NWSMU for Indians &amp; International students. For more queries, you can call us at 1800-419-5827 (Toll-free) / +91-8010503035 (India) | +79515174507 (Russia). </p>

                          <p><strong>Call for more details about the university, admission, processing, fees at MBBS in NWSMU<br /> Russia:+91-8010503035, Toll-free 1800-419-5827</strong></p>




                        </div>
                      </div>
                    </div>
                  </div>



                </div>
              </div>
            </div>


          </div>
        </section>



      </main>

    )
  }

}

export default North_western_state_medical_university_named_after_mechnikov;