import React, { Component } from "react";

export class Footer extends Component {
    render() {
        return (
            <div>
                <footer class="footer-area style4">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                <div class="single-footer-widget marbtm50-s4">
                                    <div class="our-info-box">
                                        <div class="footer-logo">
                                            <a href={process.env.PUBLIC_URL + '/'}>
                                                <img src="images/resources/logo.png" alt="Awesome Logo" />
                                            </a>
                                        </div>
                                        <div class="text">
                                            <p>We are pleased to introduce ourselves as ”Doctors-Ideal Educational Advisors” an international education facilitator who is providing support to students, aspirants to study in foreign Medical and Technical universities in Russia. </p>
                                        </div>
                                        <div class="follow-us-social-links clearfix">
                                            <span>Follw Us On:</span>
                                            <ul>
                                                <li><a href="#">Facebook</a></li>
                                                <li><a href="#">Twitter</a></li>
                                                <li><a href="#">Instagram</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-4 col-lg-5 col-md-6 col-sm-12">
                                <div class="single-footer-widget s4">
                                    <div class="title-style2">
                                        <h3>Usefull Links</h3>
                                    </div>
                                    <div class="usefull-links">
                                        <ul class="float-left">
                                            <li><a href={process.env.PUBLIC_URL + '/'}>Home</a></li>
                                            <li><a href="https://thedoctorsiea.in/about-us/" target="_blank">About Us</a></li>
                                            <li><a href="https://thedoctorsiea.in/apply-online/" target="_blank">Apply Online</a></li>
                                            <li><a href="#">Gallery</a></li>

                                        </ul>
                                        <ul class="float-left borders-left">

                                            <li><a href="#">Universities</a></li>
                                            <li><a href="https://thedoctorsiea.in/mbbs-fees-russia/" target="_blank">MBBS Fees</a></li>
                                            <li><a href="https://thedoctorsiea.in/" target="_blank">Services</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/contact-us'}>Contact Us</a></li>

                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-4 col-lg-3 col-md-12 col-sm-12">
                                <div class="single-footer-widget pdtop50-s4">
                                    <div class="title-style2">
                                        <h3>Subscribe Us</h3>
                                    </div>
                                    <div class="subscribe-box">
                                        <form class="subscribe-form" action="#">
                                            <input type="email" name="email" placeholder="Your Email" />
                                            <button class="btn-one" type="submit">Subscribe<span class="flaticon-next"></span></button>
                                        </form>
                                        <div class="text">
                                            <p><span>*</span>Subscribe us and get latest news and updates</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>

                <section class="footer-contact-info-area">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-12">
                                <ul class="footer-contact-info clearfix">
                                    <li>
                                        <div class="single-footer-contact-info">
                                            <div class="inner">
                                                <div class="icon">
                                                    <span class="icon-global"></span>
                                                </div>
                                                <div class="text">
                                                    <p><span>Head Office:</span><br /> New Delhi (India)</p>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="single-footer-contact-info">
                                            <div class="inner">
                                                <div class="icon">
                                                    <span class="icon-support1"></span>
                                                </div>
                                                <div class="text">
                                                    <p><span>Toll Free:</span><br /> 1800-419-5827</p>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="single-footer-contact-info">
                                            <div class="inner">
                                                <div class="icon">
                                                    <span class="icon-shipping-and-delivery"></span>
                                                </div>
                                                <div class="text">
                                                    <p><span>Email Us:</span><br /> contact@thedoctorsiea.org</p>
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="footer-bottom-area style3">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div class="copyright-text text-center">
                                    <p>&copy; 2023 All Rights Reserved by MBBS in Russia. Presented by <a target="_blank" href="https://thedoctorsiea.in/">Doctors-IEA Team</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div class="scroll-to-top scroll-to-target" data-target="html"><span class="fa fa-angle-up"></span></div>
                <div className="social1">
                    <ul>
                        <li><a href="tel:917428212236" target="_blank"><img src="images/phone.png" className="img-fluid" alt="" /></a></li>
                        <li><a href="https://wa.me/917428212236" target="_blank"><img src="images/wht.png" className="img-fluid" alt="" /></a></li>

                    </ul>
                </div>

                <div className="app">
                    <ul>
                        <li><a href="https://thedoctorsiea.in/apply-online/" target="_blank" ><img src="images/app.png" className="img-fluid" alt="" /></a></li>
                    </ul>
                </div>
                <div className="partner">
                    <ul>
                        <li><a href="https://thedoctorsiea.in/partner-with-us" target="_blank"><img src="images/part.png" className="img-fluid" alt="" /></a></li>
                    </ul>
                </div>
            </div>

        )
    }
}

export default Footer;	