import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import SideMenu from '../layout/SideMenu';


export class Contact_us extends Component {
    render() {

        return (
            <main>
                <Helmet>
                    <title>Contact Us For MBBS in Russia | Direct Admission</title>
                    <meta name="description" content="MBBS in Russia has become first choice for Indian students when it comes to study MBBS abroad. To Know more call Doctors-IEA MBBS in Rusia" />
                    <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                    <link rel="canonical" href="https://thedoctorsiea.org/contact-us/" />
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Contact Us For MBBS in Russia | Direct Admission" />
                    <meta property="og:description" content="MBBS in Russia has become first choice for Indian students when it comes to study MBBS abroad. To Know more call Doctors-IEA MBBS in Rusia" />
                    <meta property="og:url" content="https://thedoctorsiea.org/contact-us/" />
                    <meta property="og:site_name" content="MBBS in Russia" />
                    <meta property="article:modified_time" content="2021-03-23T12:41:42+00:00" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:label1" content="Est. reading time" />
                    <meta name="twitter:data1" content="2 minutes" />
                </Helmet>
                <section className="breadcrumb-area a1">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="inner-content clearfix">
                                    <div className="title">
                                        <h1>Contact Us</h1>
                                    </div>
                                    <div className="breadcrumb-menu float-right">
                                        <ul className="clearfix">
                                            <li><a href={process.env.PUBLIC_URL + '/'}>Home</a></li>
                                            <li className="active">Contact Us</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="contact-info-area">
                    <div className="container">

                        <div className="row">
                            <div className="col-xl-12">
                                <div className="history-content-box clearfix">
                                    <div className="single-history-content">
                                        <div className="text-box">
                                            <div className="inner">
                                                <div className="text">
                                                    <p>Russian medical universities duly listed from NMC and WHO offer several prominent features for Indian students. It makes the dream of MBBS aspirants more feasible and affordable. It offers a great opportunity to those who find themselves financially not so strong but still want to study MBBS in Russia. Several world-renowned or NMC approved medical universities already offer direct admission in medical programs to make all the admission procedure hassle-free and without facing stern entrance exam. The fees in also as low as 3-5 lakhs to do MBBS in Russia for Indian students. Russia is one of the popular foreign destinations and dreams for Indians as well as many international students who want to complete the MBBS and become a doctor. Call for MBBS in Russia Direct Admission 1800-419-5827 (Official Admission Partners of TOP 10 Universities).</p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-xl-4 col-lg-4"></div>
                            <div className="col-xl-4 col-lg-4">
                                <div className="single-contact-address-box">
                                    <span className="icon-global"></span>
                                    <h3>Head Office<br></br>New Delhi</h3>
                                    <p>Aggarwal Cyber Plaza-1, 608, 6th floor, Netaji Subhash Place, Pitampura-110034</p>
                                    <p><strong>Email:</strong> <a href='mailto:contact@thedoctorsiea.org'>contact@thedoctorsiea.org</a></p>
                                </div>
                            </div>





                        </div>

                        <div className='row'>
                            <div className='col-sm-12 text-center mt-p'>
                                <h2>Students can contact us for Direct Admission <br />at +91-8010503035 or +91-7428212236</h2>

                                <h3>Our official websites: www.thedoctorsiea.in, www.thedoctorsiea.org </h3>
                            </div>
                        </div>


                    </div>
                </section>


            </main>

        )
    }

}

export default Contact_us;