import React, { Component } from 'react';
import News from './News';


export class Topheader extends Component {
    render() {

        return (   
                           
<section class="top-bar-style2">
<div class="top-style2 clearfix">
    <div className='Container'>
        <div className='row'>
            <div className='col-sm-8'>
    <div class="top-style2-left">
    <div className='row'>
            <div className='col-sm-3'>
        <p><span class="fa fa-bullhorn"></span><span className="blink-text">Breaking News:</span> </p>
        </div>
        <div className='col-sm-9'>
        <News /> 
        </div>
        </div>
    </div>
    </div>
    <div className='col-sm-4'>
    <div class="top-style2-right">
        
        <ul class="topbar-social-links">
        <li><div id="google_translate_element">  </div></li>
            <li><a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
            <li><a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
            <li><a href="#"><i class="fa fa-skype" aria-hidden="true"></i></a></li>
            <li><a href="#"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
        </ul>
    </div>
    </div>
    </div>
</div>
</div>
</section>

     )
    }
}
export default Topheader;