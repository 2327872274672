import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import SideMenu from '../layout/SideMenu';


export class Thank_you extends Component {
    render() {

        return (       
           <main>

<section class="breadcrumb-area a1">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="inner-content clearfix">
                            <div class="title">
                                <h1>Thank you</h1>
                            </div>
                            <div class="breadcrumb-menu float-right">
                                <ul class="clearfix">
                                    <li><a href={process.env.PUBLIC_URL + '/'}>Home</a></li>
                                    <li class="active">Thank you</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="company-overview-area">
            <div class="container">
               
                <div class="row">
                    <div class="col-xl-12">
                        <div class="history-content-box clearfix">
                          
                            
                                <div class="single-history-content">
                                   
                                    <div class="text-box">
                                        <div class="inner">
                                         
                                            <div class="text">
                                            <h2 className='text-center'>Thank You  for your message. it has been sent</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                              
                         
                        </div>
                    </div>
                </div>
                

            </div>
        </section>
       
         
           </main>

)
}

}

export default Thank_you;