import React, { Component } from 'react'

export class News extends Component {
    render() {

        return (       
  
        <marquee direction="left" speed={1000}><span className="text-white"> Dear students and parents: <span className="blink-text">Doctors-IEA</span> is proud to announce that we are the <span className="blink-text">Direct admission partners</span> of the Top 15 <span className="blink-text">Russian Medical Universities</span>. Book your seat now at <span className="blink-text">1800-419-5827</span>.
</span></marquee> 
   

     )
    }
}
export default News;