import React, { Component } from 'react'

export class Logo extends Component {
    render() {

        return (   
     
            <div> 
    <a href={process.env.PUBLIC_URL + '/'}><img src="images/resources/logo.png" alt="Awesome Logo" /></a>
            </div>  
            
     )
    }
}
export default Logo;