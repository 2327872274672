import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import SideMenu from '../layout/SideMenu';


export class About_us extends Component {
    render() {

        return (       
           <main>
<Helmet>
<title>About Doctors-Ideal Educational Advisors - MBBS in Russia</title>
<meta name="description" content="International education facilitator providing direct admissions to Indian &amp; Foreign Students in Russian Universities." />
<link rel="canonical" href="https://thedoctorsiea.in/about-us/" />
<meta property="og:locale" content="en_US" />
<meta property="og:type" content="article" />
<meta property="og:title" content="About Doctors-Ideal Educational Advisors - MBBS in Russia" />
<meta property="og:description" content="International education facilitator providing direct admissions to Indian &amp; Foreign Students in Russian Universities." />
<meta property="og:url" content="https://thedoctorsiea.in/about-us/" />
<meta property="article:publisher" content="https://www.facebook.com/doctorsieaindiarussia/" />
<meta property="article:modified_time" content="2021-11-20T05:45:13+00:00" />
<meta property="og:image" content="https://thedoctorsiea.in/wp-content/uploads/2019/02/About-usDoctors-ideal-educational-advisors-russia-india.jpg" />
<meta name="twitter:card" content="summary" />
<meta name="twitter:label1" content="Est. reading time" />
<meta name="twitter:data1" content="2 minutes" />
</Helmet>
<section class="breadcrumb-area a1">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="inner-content clearfix">
                            <div class="title">
                                <h1>About Us</h1>
                            </div>
                            <div class="breadcrumb-menu float-right">
                                <ul class="clearfix">
                                    <li><a href={process.env.PUBLIC_URL + '/'}>Home</a></li>
                                    <li class="active">About Us</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="company-overview-area">
            <div class="container">
               
                <div class="row">
                    <div class="col-xl-12">
                        <div class="history-content-box clearfix">
                          
                            
                                <div class="single-history-content">
                                   
                                    <div class="text-box">
                                        <div class="inner">
                                         
                                            <div class="text">
                                            <h2 class="title">Top Medical Universities of Russia, <span>Top Technical Universities of Russia</span></h2>
                              
                                            <p>We are pleased to introduce ourselves as "Doctors-Ideal Educational Advisors" an international education facilitator who is providing support to students, aspirants to study in foreign Medical and Technical universities in Russia.</p>
                        
                                            <p><strong>Doctors-IEA </strong>provides general information about the Study in Russia. It holds the information about universities, financial aids available to support students in their education, student visa process, living cost, student life, and many more. This website is established with a view to helping students for making they're Studying Abroad in Russia Dream true.<strong> </strong>The contents of this website are constantly updated to give students the latest and fresh information. We have found many Indian students as well as foreign students taking advantage of this website to gather the required information about <strong>Education in Russia</strong>.</p>
<p><strong>T</strong>oday the urge to get enrolled in <strong>Universities and Colleges</strong> is high among students but due to stiff competitions, high financial involvements, and some government policies, it is becoming tough and costly day by day to take admission to Universities in student's native countries.</p>
<p><strong>Doctors-IEA</strong> has come up with the solution to these problems at that time of life when the students need it the most. We assist our students to study in various fields of Medical, Engineering, Economics, Computer Science, Aviation Engineering and many other programs in the best <strong>Medical, Engineering, Computer and Aviation Universities of Russia </strong>especially.</p>
<p><strong>Our Mission: </strong>As a professional consultant, our objective mission is to provide complete knowledge and assistance to students to make the right choices to study higher education. Our main mission is to guide students in their abroad study process. We want students to choose the correct destination for their further studies. We want to help them in choosing suitable universities/colleges for them, where they can secure their future.</p>
<p><strong>Last Words...</strong> Thank you for visiting<strong> <a href="https://thedoctorsiea.in/">Doctors-Ideal Educational Advisors</a></strong>. Every day we receive many comments from students. We have taken all these comments positively and hope for more comments about the mistakes and changes you wish to bring in this website.</p>
<p>Sincerely yours,<br />
  <strong>Doctors-IEA</strong> …always first to make your career</p>
<p><strong>(A group of Doctors)</strong></p>

<p><strong>Doctors-IEA </strong>helping students getting enrolled in Top Medical Universities for <a href="https://thedoctorsiea.in/study-mbbs-russia/"><strong>MBBS in Russia</strong></a>. Doctors-IEA has Direct Contracts with Top leading Universities of Russia. <strong>Doctors-IEA</strong> helping students in choosing the best Medical Universities in Russia according to student's choice &amp; their budget. It's a great opportunity for Foreign students to get direct <strong>MBBS Admission in Russia</strong> at low processing &amp; tuition fees, for more details write to us at thedoctors.iea@gmail.com </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                              
                         
                        </div>
                    </div>
                </div>
                

            </div>
        </section>
       
         
           </main>

)
}

}

export default About_us;