import React, { Component } from 'react';
import Topheader from './Topheader';
import News from './News';
import { Menu } from './Menu';
import Logo from './Logo';
import Call from './Call';

export class Header extends Component {
    state = {
        show: false,
      }    
    
    render() {        
        return (
           <div>
                <Topheader />
 <header class="main-header header-style2 stricky">
            <div class="inner-container clearfix">
<Menu />
            </div>
        </header>

           </div>
        

            )
        }
    }
    
    export default Header;