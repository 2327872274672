import React, { Component }  from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import SwiperCore, { FreeMode, Pagination, Autoplay } from "swiper";

export class Services extends Component {

    
    render() {

        return (
            <div class="project-carousel-v2 owl-carousel owl-theme">
            <div class="single-project-style2">
            <div class="img-holder">
                <img src="images/projects/lat-pro-v2-1.jpg" alt="Awesome Image" />
                <div class="read-more">
                    <a href="#"><span class="icon-next"></span></a>
                </div>
                <div class="title-box">
                     <h3>Crimea Federal University</h3>
                </div>
            </div>
        </div>
    
        <div class="single-project-style2">
            <div class="img-holder">
                <img src="images/projects/lat-pro-v2-2.jpg" alt="Awesome Image" />
                <div class="read-more">
                    <a href="#"><span class="icon-next"></span></a>
                </div>
                <div class="title-box">
                     <h3>Kazan State Medical University</h3>
                </div>
            </div>
        </div>
    
        <div class="single-project-style2">
            <div class="img-holder">
                <img src="images/projects/lat-pro-v2-3.jpg" alt="Awesome Image" />
                <div class="read-more">
                    <a href="#"><span class="icon-next"></span></a>
                </div>
                <div class="title-box">
                    <h3>Rostov State Medical University</h3>
                </div>
            </div>
        </div>
      
        <div class="single-project-style2">
            <div class="img-holder">
                <img src="images/projects/lat-pro-v2-4.jpg" alt="Awesome Image" />
                <div class="read-more">
                    <a href="#"><span class="icon-next"></span></a>
                </div>
                <div class="title-box">
                    <h3>Kazan (Volga region) Federal University</h3>
                </div>
            </div>
        </div>
        </div>    
  
   
            )
        }
    }
export default Services;