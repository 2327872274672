import React, { Component }  from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import SwiperCore, { FreeMode, Pagination, Autoplay } from "swiper";

export class Courses extends Component {

    
    render() {

        return (
                  
            <div class="working-process-carousel owl-carousel owl-theme">
                          
            <div class="single-working-process-style2">
                <div class="top-box clearfix">
                    <div class="count">
                        <h1>01</h1>
                    </div>
                    <div class="icon">
                        <span class="fa fa-plus"></span>
                    </div>
                </div>
                <div class="inner">
                    <h3>Medicine (MBBS)</h3>
                    <p><strong>Price: 3500-9500$</strong></p>
                </div>
            </div>
          
            <div class="single-working-process-style2">
                <div class="top-box clearfix">
                    <div class="count">
                        <h1>02</h1>
                    </div>
                    <div class="icon">
                        <span class="fa fa-hospital-o"></span>
                    </div>
                </div>
                <div class="inner">
                    <h3>Dentistry</h3>
                    <p><strong>Price: 3000-6000$</strong></p>
                </div>
            </div>
          
            <div class="single-working-process-style2">
                <div class="top-box clearfix">
                    <div class="count">
                        <h1>03</h1>
                    </div>
                    <div class="icon">
                        <span class="fa fa-medkit"></span>
                    </div>
                </div>
                <div class="inner">
                    <h3>Pharmacy</h3>
                    <p><strong>Price: 3000-5000$</strong></p>
                </div>
            </div>
            
            
            </div>
          

           
       
        
  
   
            )
        }
    }
export default Courses;