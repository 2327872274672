import React, { Component } from 'react';
import { createBrowserHistory } from 'history';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Logo from './Logo';

export class Menu extends Component {
    render() {
        return (
            <div>
                <div class="logo-box-style2 float-left">
                    <Logo />
                </div>
                <div class="main-menu-box float-right">
                    <nav class="main-menu style2 clearfix">
                        <div class="navbar-header clearfix">
                            <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                                <span class="icon-bar"></span>
                                <span class="icon-bar"></span>
                                <span class="icon-bar"></span>
                            </button>
                        </div>
                        <div class="navbar-collapse collapse clearfix">
                            <ul class="navigation clearfix">
                                <li class="current"><a href={process.env.PUBLIC_URL + '/'}>Home</a></li>
                                <li><a href="https://thedoctorsiea.in/why-us/" target="_blank">About Us</a></li>
                                <li class="dropdown"><a href="#">Universities</a>
                                    <ul>
                                        <li><a href="https://rrecrussia.com/crimea-federal-university/" target="_blank">Crimea Federal University</a></li>
                                        <li><a href="https://thedoctorsiea.in/kazan-state-medical-university/" target="_blank">Kazan State Medical University</a></li>
                                        <li><a href="https://rrecrostov.com/" target="_blank">Rostov State Medical University</a></li>
                                        <li><a href={process.env.PUBLIC_URL + '/kazan-volga-region-federal-university'}>Kazan (Volga region) Federal University</a></li>
                                        <li><a href={process.env.PUBLIC_URL + '/volgograd-state-medical-university'}>Volgograd State Medical University</a></li>
                                        <li><a href="https://thedoctorsiea.in/kursk-state-medical-university-russia/" target="_blank">Kursk State Medical University</a></li>
                                        <li><a href={process.env.PUBLIC_URL + '/peoples-friendship-university-medical-institute'}>Peoples Friendship University</a></li>
                                        <li><a href={process.env.PUBLIC_URL + '/north-ossetian-state-medical-academy'}>North Ossetian State Medical Academy</a></li>
                                        <li><a href={process.env.PUBLIC_URL + '/north-western-state-medical-university-named-after-mechnikov'}>North-Western State Medical University named after I.I. Mechnikov</a></li>
                                    </ul>
                                </li>

                                <li><a href="https://thedoctorsiea.in/mbbs-fees-russia/">MBBS Fees</a></li>
                                <li><a href="https://thedoctorsiea.in/">Services</a></li>
                                <li><a href={process.env.PUBLIC_URL + '/contact-us'}>Contact Us</a></li>
                            </ul>
                        </div>
                    </nav>
                    <div class="mainmenu-right style2">
                        <div class="button">
                            <a class="btn-one" href="https://thedoctorsiea.in/apply-online/" target="_blank">Apply Online<span class="flaticon-next"></span></a>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
export default Menu;