import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import SideMenu from '../layout/SideMenu';
import Youtube from '../sliders/Youtube';


export class Apply_now extends Component {
    render() {

        return (
            <main>
                <Helmet>
                    <title>Doctors-IEA Team | The Official Representative of Kazan State Med University</title>
                    <meta name="description" content="Doctors-Ideal educational Advisors is the official representative of Kazan state medical university, Russia. For more info call 1800-419-5827" />
                    <link rel="canonical" href="https://studymbbsinkazan.com/about-doctors-iea/" />
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Doctors-IEA Team | The Official Representative of Kazan State Med University" />
                    <meta property="og:description" content="Doctors-Ideal educational Advisors is the official representative of Kazan state medical university, Russia. For more info call 1800-419-5827" />
                    <meta property="og:url" content="https://studymbbsinkazan.com/about-doctors-iea/" />
                    <meta property="og:site_name" content="Kazan State Med University" />
                    <meta property="article:modified_time" content="2023-01-11T06:16:57+00:00" />
                    <meta property="og:image:width" content="950" />
                    <meta property="og:image:height" content="450" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:label1" content="Est. reading time" />
                    <meta name="twitter:data1" content="2 minutes"></meta>
                </Helmet>

                <div className="page-title__area pt-110 a1">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="page__title-wrapper text-center">
                                    <h3 className="pb-100">Apply now </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <nav className="breadccrumb-bg">
                        <ul className="breadcrumb justify-content-center pt-20 pb-20">
                            <li className="bd-items"><a href={process.env.PUBLIC_URL + '/'}>Home</a></li>
                            <li className="bd-items bdritems">|</li>
                            <li className="bd-items"> <a href={process.env.PUBLIC_URL + '/about_doctors_iea'}> Apply now</a></li>
                        </ul>
                    </nav>
                </div>


                <div class="united-stats-area  ptb-100">
                    <div class="container">
                        <div class="row">

                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-sm-12">

                                <div class="sidebar__deatils">
                                    <div class="united-states">
                                        <iframe width="100%" height="415" src="https://www.youtube.com/embed/CDVD8lBW4-s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                        <h1>What advantages do Students receive in Kazan State Medical University? </h1>
                                        <p>Pursuing MBBS in KSMU is totally a different experience for students enrolling there from India or other countries. The teachers have abundant of knowledge and experienced in their fields. Teachers and professors pay equal and undivided attention to each and every student. Students are allotted to the most prolific training and research program. There is a consistent approach from the administration and teaching staff to elevate your performance and progression throughout the course. For all these exceptional teaching and training <strong>Kazan State Medical University fees</strong> is very nominal because it is a government-funded institution.</p>
                                        <p>Apart from study, students are also encouraged to participate in various extracurricular activities to hone up their collateral skills and personality. They can participate in activities, games and entertainment such as tennis, basketball, swimming, skating, football and skiing in winter. They can mix up with international students and experience dynamic thoughts, culture and tradition. Overall, they explore and experience healthy and soothing campus life in the university.</p>

                                        <h2>Online Application Form for KSMU, Kazan 2023-24</h2>


                                        <form id="ttm-quote-form" className="row ttm-quote-form clearfix" method="post" action="#">

                                            <div class="row">
                                                <div class="col-xl-6">
                                                    <input name="name" class="contact__input" type="text" placeholder="Your Name" required />
                                                </div>
                                                <div class="col-xl-6">
                                                    <input name="email" class="contact__input" type="email" placeholder="Your Email" required />
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-6">
                                                    <input name="text" class="contact__input" type="text" placeholder="Phone Number" required />
                                                </div>
                                                <div class="col-xl-6">
                                                    <input name="subject" class="contact__input" type="text" placeholder="Country" required />
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-xl-6">
                                                    <input name="text" class="contact__input" type="text" placeholder="City" required />
                                                </div>
                                                <div class="col-xl-6">
                                                    <input name="subject" class="contact__input" type="text" placeholder="Desired University" required />
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-xl-12">
                                                    <textarea name="message" class="contact__input txt-area " cols="30" rows="10" placeholder="Message"></textarea>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-12">
                                                    <button class="theme-btn" type="submit">Submit Now</button>
                                                </div>
                                            </div>

                                        </form>

                                        <div className='col-sm-12 bg-orange text-center pt-50 pb-50 mt-50'>

                                            <h5>206 Yrs  Old Top Government Medical University Of Russia</h5>
                                            <h6>Kazan State Medical University</h6>
                                            <h6>Address: Ulitsa Butlerova, 49, Kazan, Republic of Tatarstan, Russia, 420012</h6>
                                            <h6>Contact details:+91-8010503035, +91-7428212236, (Indian office)</h6>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="popular-country-2 pt-200 pb-120 youtube-bg">
                    <div className="container">
                        <div className="famous-countries">
                            <Youtube />
                        </div>
                    </div>
                </section>

            </main>




        )
    }

}

export default Apply_now;